<template>
  <div class="info">
    <AppText
      variant="div"
      size="22px"
      class="font-medium"
      mt="20px"
    >
      {{ t('signup.invitation.invalidInvitation.title') }}
    </AppText>

    <AppText
      variant="div"
      size="15px"
      :line-height="1.5"
      mt="30px"
      mb="40px"
      align="center"
      style="width: 340px;"
    >
      {{ t('signup.invitation.invalidInvitation.description') }}
    </AppText>

    <FButton to="/">
      {{ t('signup.invitation.invalidInvitation.button') }}
    </FButton>
  </div>
</template>

<script>
import { useTemporaryStore } from '@/store';

export default {
  name: 'InvalidInvitation',
  setup() {
    const { store } = useTemporaryStore();
    store.clearRegistrationState();
  },
};
</script>

<style lang="scss" scoped>
.info {
  padding: 31px 0 53px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
