<template>
  <div class="flex-center flex-column" style="padding: 85px 55px;">
    <div class="icon-wrapper">
      <AppIcon name="logout" is-img-tag />
    </div>

    <AppText
      size="18px"
      class="font-weight"
      variant="div"
      mb="40px"
      align="center"
    >
      {{ t('signup.logout.label') }}
    </AppText>

    <FButton :loading="isLoading" type="danger" @click="onLogout">
      {{ t('signup.logout.button') }}
    </FButton>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

import { logout } from '@/api/auth';

export default defineComponent({
  setup() {
    const isLoading = ref(false);
    const onLogout = async () => {
      isLoading.value = true;
      try {
        await logout({ pushToHome: false, useLogoutEndpoint: true });
      } catch (error) {
        await logout({ pushToHome: false, useLogoutEndpoint: false });
      }
      isLoading.value = false;
    };

    return { isLoading, onLogout };
  },
});
</script>

<style scoped lang="scss">
.icon-wrapper {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--color-black-005);

  @include flex-center;

  margin-bottom: 40px;

}
</style>
