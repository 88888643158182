<template>
  <div class="close-button">
    <AppIcon
      name="x-close"
      size="18px"
      opacity="0.4"
      is-img-tag
    />
  </div>
</template>

<style scoped lang="scss">
.close-button {
  cursor: pointer;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black-005);
  @include transition-base('background');

  &:hover {
    background: var(--color-black-01);

    :deep(.icon) {
      opacity: 1;
    }
  }
}
</style>
