import { i18n } from '@/plugins/localization';
import { useNotifications } from '@/composables/useNotifications';
import { useStore } from '@/store';
import { useTempFetch } from '@/composables/useFetch/temporaryInstance';
import { useTemporaryStore } from '@/store/stores/useTemporaryStore';

import { checkAccountWasPrunedHalder } from './helpers';

export const merchantRegistrate = async ({ companyName, baseCurrency }) => {
  const { axiosTempWrapper } = useTempFetch();
  const { store } = useTemporaryStore();
  const {
    data, isSuccess, errorObject, error,
  } = await axiosTempWrapper({
    type: 'post',
    url: '/merchants/register',
    params: { companyName, baseCurrency },
    isDefaultErrorNotificationEnabled: false,
  });
  if (isSuccess) {
    store.updateRegistrationState({ companyName, currency: baseCurrency, uid: data.uid });
  }

  return {
    data, isSuccess, errorObject, error,
  };
};

export const twoFaCodeGenerate = async (text = 'Switch', isFresh = false) => {
  const { axiosTempWrapper } = useTempFetch();

  const { data, isSuccess, error } = await axiosTempWrapper({
    type: 'post',
    url: '/merchants/totp/generate',
    params: {
      isFreshResponse: isFresh,
    },
  });

  const otpLink = isSuccess ? `otpauth://totp/${text}?secret=${data.secret}` : '';

  checkAccountWasPrunedHalder(error?.data?.code);

  return {
    isSuccess, data, otpLink, error,
  };
};

export const twoFaEnable = async (totpCode) => {
  const { axiosTempWrapper } = useTempFetch();
  const { isSuccess, error, errorObject } = await axiosTempWrapper({
    type: 'post',
    url: '/merchants/totp/enable',
    params: { totpCode },
  });

  checkAccountWasPrunedHalder(error?.data?.code);

  return { isSuccess, errorObject };
};

export const generateRecoveryCodes = async () => {
  const { axiosTempWrapper } = useTempFetch();
  const { data, isSuccess, error } = await axiosTempWrapper({
    type: 'get',
    url: '/auth/totp/recovery-keys/generate',
    isErrorNotification: false,
  });

  const codesWasAdded = !isSuccess && error?.data?.message === 'KEYS_ALREADY_ADDED';

  checkAccountWasPrunedHalder(error?.data?.code);

  return {
    data, isSuccess, error, codesWasAdded,
  };
};

export const resendEmail = async () => {
  const { t } = i18n.global;
  const { axiosTempWrapper, successHandler } = useTempFetch();
  const { isSuccess, data, error } = await axiosTempWrapper({
    type: 'post',
    url: '/merchants/register/resend-email',
  });
  if (isSuccess) {
    successHandler(t('signup.notification.resendEmailSuccess'));
  }

  checkAccountWasPrunedHalder(error?.data?.code);

  return { isSuccess, data, error };
};

export const completeRegistration = async ({ email, language }) => {
  const { t } = i18n.global;
  const { axiosTempWrapper } = useTempFetch();
  const { isSuccess, error, errorObject } = await axiosTempWrapper({
    type: 'patch',
    url: '/merchants/complete-registration',
    params: { email, language },
  });

  if (!isSuccess && errorObject?.message === 'ACCOUNT_EXISTS') {
    const { addNotification } = useNotifications();

    addNotification({
      text: t(`errors.${errorObject?.message}`),
      config: { color: 'error', duration: 5000 },
    });
  }

  checkAccountWasPrunedHalder(error?.data?.code);
  return { isSuccess, errorObject };
};

export const verifyEmailHandler = async (code) => {
  const store = useStore();

  const language = store.language;

  const { axiosTempWrapper } = useTempFetch();
  const {
    data, isSuccess, error, errorObject,
  } = await axiosTempWrapper({
    type: 'post',
    url: '/merchants/register/verify-code',
    params: { code: String(code), language },
  });

  if (isSuccess) {
    await new Promise((resolve) => { setTimeout(resolve, 500); });
  } else {
    checkAccountWasPrunedHalder(error?.data?.code);
  }

  return {
    isSuccess, uid: data?.uid, error, errorObject,
  };
};

export const initMerchantIvnitation = async (invitationLink) => {
  const { axiosTempWrapper } = useTempFetch();
  const { data, isSuccess, error } = await axiosTempWrapper({
    type: 'post',
    url: '/merchants/register/invitation',
    params: { invitationLink },
    isErrorNotification: false,
  });

  return { data, isSuccess, error };
};

export const completeInvitation = async (baseCurrency) => {
  const store = useStore();
  const language = store.language;

  const { axiosTempWrapper } = useTempFetch();
  const { data, isSuccess, error } = await axiosTempWrapper({
    type: 'patch',
    url: '/merchants/complete-registration/invitation',
    params: { baseCurrency, language },
  });

  return { data, isSuccess, error };
};
