<template>
  <div class="block">
    <router-link to="/" class="close">
      <AppIcon name="x-close" size="18px" is-img-tag />
    </router-link>

    <div class="pic">
      <AppIcon name="laptop" size="47px" is-img-tag />
    </div>

    <AppText
      variant="div"
      size="23px"
      align="center"
      class="desc font-medium"
    >
      <i18n-t keypath="signup.forbidden.openOnDesktop">
        <template #link>
          <router-link to="/">
            {{ productionURL }}
          </router-link>
        </template>
      </i18n-t>
    </AppText>

    <div class="image" />
  </div>
</template>

<script setup>
const productionURL = process.env.VUE_APP_PRODUCTION_URL;
</script>

<style lang="scss" scoped>
.block {
  display: none;

  @include mq('mobile') {
    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 3000;
    position: relative;
    background: #fff;
  }

  .close {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--color-black-005);
    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
    align-self: flex-start;
  }

  .pic {
    margin-top: 104px;
  }

  :deep(.text) {
    margin: 27px 40px 22px;
    a {
      text-decoration: none;
      color: var(--color-primary);
    }
  }

  .image {
    margin: 30px 0 0 25px;
    filter: drop-shadow(0px 6.815px 51.1125px var(--color-black-01));
    width: calc(100% - 25px);
    height: 1363px;
    background: url('~@/assets/images/chrome-desktop.svg') no-repeat;
    background-size: 2453px 1363px;
  }
}
</style>
