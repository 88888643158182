<template>
  <!-- <div class="sign-up-wrap"> -->
  <div
    class="sign-up-wrap"
    :class="{
      'is-ready': isReady,
      'is-invitation': isInvitationPage,
    }"
  >
    <CloseButton class="close-btn" @click="goToLanding" />

    <SignUpBlock />

    <div class="sign-up">
      <SignUpHeader @click="goToLanding" />

      <div ref="wrapper" class="step-wrapper d-flex flex-column align-items-center">
        <div class="form__wrapper d-flex flex-column align-items-center">
          <div ref="wrapperContent" class="wrapper-content">
            <InvalidInvitation v-if="isReady && isInviteInvalid" />
            <LogoutAction v-else-if="isReady && isAuthenticated" />
            <router-view v-else-if="isInvitationPage" :data="invitationData" />
            <router-view v-else />
          </div>
        </div>
        <div class="d-flex justify-content-center mt-8">
          <AppText v-if="!isAuthenticated" size="12px" color="var(--color-black-04)">
            <i18n-t keypath="signup.footer.description">
              <template #terms>
                <ExternalLink
                  size="12px"
                  :href="termsUrl"
                  has-primary-color
                  :has-prepend="false"
                  :has-append="false"
                >
                  {{ t('signup.footer.terms') }}
                </ExternalLink>
              </template>
            </i18n-t>
          </AppText>
          <AppText v-else size="12px" color="var(--color-black-04)">
            <i18n-t keypath="signup.footer.authenticatedMerchant">
              <template #merchantName>
                {{ companyName }}
              </template>
            </i18n-t>
          </AppText>
        </div>
        <UidBadge v-if="isAuthenticated" :uid="uid" class="uid-signup" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, defineComponent, onBeforeMount, onMounted, ref,
} from 'vue';
import { useRouter } from 'vue-router';

import CloseButton from '@/components/Buttons/RoundButton.vue';
import ExternalLink from '@/components/ExternalLink.vue';
import UidBadge from '@/components/UidBadge.vue';

import { useRoute } from '@/router';
import { apiHealthCheck } from '@/api/auth';
import { useTemporaryStore } from '@/store';
import { useUser } from '@/composables/useUser';
import { initMerchantIvnitation } from '@/views/client/SignUp/api';

import InvalidInvitation from './InvalidInvitation.vue';
import LogoutAction from './LogoutAction.vue';
import SignUpBlock from './SignupBlock.vue';
import SignUpHeader from './SignupHeader.vue';

export default defineComponent({
  components: {
    SignUpHeader,
    SignUpBlock,
    LogoutAction,
    InvalidInvitation,

    UidBadge,
    ExternalLink,
    CloseButton,
  },
  setup() {
    const router = useRouter();
    const goToLanding = () => {
      router.replace({ name: 'LANDING' });
    };

    const wrapper = ref(null);
    const wrapperContent = ref(null);
    const PADDING = 40;

    const resizeObserver = new ResizeObserver((() => {
      let contentHeight = wrapperContent.value?.scrollHeight;
      if (contentHeight < 220) {
        contentHeight = 220;
      }
      if (contentHeight > 600) {
        contentHeight = 600;
      }

      contentHeight += (PADDING * 2);

      if (wrapper.value && wrapper.value.style) {
        wrapper.value.style.setProperty('--contentHeight', `${contentHeight || '330'}px`);
      }
    }));

    const route = useRoute();
    const invitationData = ref({});
    const isInvitationPage = route.name === 'SIGN_UP_INVITATION';
    const isInviteInvalid = ref(false);
    const isReady = ref(false);
    onBeforeMount(async () => {
      apiHealthCheck();

      if (isInvitationPage) {
        const { data, isSuccess } = await initMerchantIvnitation(route.params.hash);
        if (isSuccess) {
          invitationData.value = data;
        } else {
          isInviteInvalid.value = true;
          wrapper.value.style.setProperty('--contentHeight', '380px');
        }
      }
      isReady.value = true;
    });

    onMounted(() => {
      setTimeout(() => { resizeObserver.observe(wrapperContent.value); }, 0);
    });

    const termsUrl = `https://${process.env.VUE_APP_DOCS_URL}/home/terms-of-use`;

    const {
      email, role, companyName, uid,
    } = useUser();
    const { registration } = useTemporaryStore();

    const isAuthenticated = computed(() => email.value && role.value && !registration.value.companyName && !registration.value.uid);

    return {
      goToLanding,

      wrapper,
      wrapperContent,
      padding: computed(() => `${PADDING}px`),

      termsUrl,

      isAuthenticated,
      companyName,
      uid,

      isReady,
      isInvitationPage,
      invitationData,
      isInviteInvalid,
    };
  },
});
</script>

<style scoped lang="scss">
.sign-up-wrap {
  position: relative;

  .close-btn {
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

.sign-up {
  background-color: #f7f7f7;
  min-height: 100vh;

  @include mq('mobile') {
    display: none;
  }
}

.form__wrapper{
  position: relative;
  background-color: var(--color-white);
  border: 1px solid var(--color-black-008);
  box-sizing: border-box;
  box-shadow: 0 4px 8px var(--color-black-004);
  border-radius: 20px;
  width: 600px;
  padding: v-bind('padding');

  max-height: var(--contentHeight);
  min-height: var(--contentHeight);
  transition: all 0.1s cubic-bezier(0, 1.2, 1, 0.97);
}

.sign-up-wrap.is-invitation {
  opacity: 0;
  @include transition-base(opacity, 0.1s);
  &.is-ready {
    opacity: 1;
  }
  .form__wrapper {
    transition: none;
  }
}

.wrapper-content {
  width: 100%;
  position: relative;
}

.uid-signup {
  margin-top: 16px;
  border-radius: 30px;
  padding: 2px 9px 2px 6px;
  align-items: center;

  :deep(.icon) {
    width: 16px;
    height: 16px;
  }
  :deep(span.text) {
    font-size: 12px;
    margin: 0 0 0 7.25px;
  }
}
</style>
